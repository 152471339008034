<!--

FormInput
------------

Props
- name
- type
- placeholder
- icon: to be shown on the right

-->

<template>
  <div class="relative">
    <div :class="['relative h-full flex', { disabled: disabled }]">
      <input 
        v-model="value" 
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :class="[
          'w-full',
          inputStyle,
          {
            'pl-8': icon,
            'pl-2': !icon
          }
        ]"
        :type="type" 
        :disabled="disabled"
        :required="required" 
        @keypress="handleKeyPress"
        @input="emit('update:modelValue', value)" 
      />
      <Icon v-if="icon" :name="icon" :class="[
        'absolute left-0 top-1/2 -translate-y-1/2 transform',
        iconStyle
      ]" />
      <Icon v-if="clearable" name="close-line" :class="[
        'absolute right-0 top-1/2 z-10 -translate-y-1/2 transform cursor-pointer',
        clearableStyle
      ]" @click.stop="clear()" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string
    type: string
    placeholder?: string
    autocomplete?: string,
    icon?: string
    modelValue?: string | number | null
    clearable?: boolean
    disabled?: boolean
    inputStyle?: string
    iconStyle?: string
    clearableStyle?: string
    required?: boolean
  }>(),
  {
    inputStyle:
      'border-b border-neutral-600 bg-white/0 py-3 pr-2 outline-none placeholder:text-neutral-600 focus:border-blue',
    iconStyle: 'text-2xl text-neutral-500',
    clearableStyle: ' text-2xl text-neutral-700',
    required: false
  }
)

const value = ref(props.modelValue)

watch(
  () => props.modelValue,
  () => {
    value.value = props.modelValue
  }
)

const handleKeyPress = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    emit('enterPressed')
  }
}

const emit = defineEmits(['update:modelValue', 'clear', 'enterPressed'])

const clear = () => {
  emit('update:modelValue', null)
  emit('clear')
}
</script>

<style scoped>
.disabled::before {
  content: '';
  @apply absolute;
  @apply w-full;
  @apply h-full;
  @apply z-[1];
  @apply cursor-pointer;
}
</style>
